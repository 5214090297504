import baseContextComponent from "@/components/baseContextComponent";
import api from "@/services/api";
import moment from "moment";
import { Component } from "vue-property-decorator";

@Component({})
export default class ReportVendite extends baseContextComponent {
	elencoReport: any = [];
	created() {
		this.getData();
	}

	getData() {
		var self = this;
		api.getElencoReport()
			.then(res => {
				self.elencoReport = res;
			})
			.catch(err => {});
	};

	generaReport(report) {
		var url = report.url;
		for(var i=0; i<report.parametri.length; i++) {
			if(report.parametri[i].tipo == 'data') {
				var data = moment(report.parametri[i].value).format("YYYYMMDD");
				url += '/' + data;
			}
		}
		window.open(api.endpoint.REPORT + '/' + url, "_blank");
	}

	impostaDate(report, tempo, periodo) {
		for(var i=0; i<report.parametri.length; i++) {
			var parametro = report.parametri[i];
			if(parametro.tipo == 'data' && parametro.nomeParametro == "dataInizio") {
				parametro.value = moment().subtract(tempo, periodo).toDate();
			} else if (parametro.tipo == 'data' && parametro.nomeParametro == "dataFine") {
				parametro.value = moment().toDate();
			}
		}
	}
}